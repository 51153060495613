import * as React from 'react';
import { css } from '@emotion/core';

import ContentCenter from '../components/layout/ContentCenter';
import App from '../components/layout/App';
import Cta from '../components/base/Cta';
import Content from '../components/base/Content';

const NotFoundPage = () => {
  return (
    <App>
      <ContentCenter>
        <Content
          css={css`
            text-align: center;
          `}
        >
          <h1>Page introuvable</h1>
          <p>
            Nous n'avons pas trouvé de résultat pour cette page. <br />
            Vérifiez que l'URL du site est correcte.
          </p>
          <div
            css={css`
              margin-top: 4rem;
            `}
          >
            <Cta to="/" theme="neutral">
              Retour à la page d'accueil
            </Cta>
          </div>
        </Content>
      </ContentCenter>
    </App>
  );
};
export default NotFoundPage;
